import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetAssociatedProductQuery from '@/modules/flagship/investor-goal/associated-product/application/queries/get-associated-product-query';
import GetCustomerKuspitPositionQuery from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries/get-customer-kuspit-position-query';
import GetHasKuspitAccountQuery from '@/modules/my-investment/kuspit/customer-has-account/application/queries/get-has-kuspit-account-query';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import { InvestorGoalsDto } from '@/modules/flagship/investor-goal/link-sowos-pocket/domain/dtos/investor-goals-dto';
import {
  CustomerInvestorGoalBalanceQueryEntity,
} from '@/modules/flagship/customer-investor-goal-balance/domain/entities/customer-investor-goal-balance-query-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

type ArrayAssociatedProduct = {
  investor_goal_id: string;
  label: string;
  active_goal_amount: number;
  active_goal_amount_field: string;
  recurrent_assigned_amount: string;
}

export default class StepDistributeBalancePocketViewModel {
  @Inject(TYPES.GET_ASSOCIATED_PRODUCT_QUERY)
  private readonly get_associated_product_query!: GetAssociatedProductQuery;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  private readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_customer_kuspit_position_query!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals.step-distribute-balance-pocket';

  private readonly view!: Vue;

  associated_product: Array<ArrayAssociatedProduct> = [];

  balance = 0;

  free_balance = 0;

  goals_selected: Array<string> = [];

  is_disabled = false;

  assigned_balance = 0;

  has_active_goals = false;

  private investor_goals_dto: InvestorGoalsDto = {
    total_active_goals_balance: '',
    unassigned_balance: '',
    investor_goals: [],
  };

  private current_investor_goal: InvestorGoalsDto = {
    total_active_goals_balance: '',
    unassigned_balance: '',
    investor_goals: [],
  };

  private customer_investor_goal_balance: CustomerInvestorGoalBalanceQueryEntity = {
    investment_product_id: '',
  };

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  // eslint-disable-next-line max-len
  initialize = async (associated_product_id: string, goals_selected: Array<string>, current_investor_goal: any) => {
    this.view.$emit('loadingStep', true);
    this.customer_investor_goal_balance.investment_product_id = associated_product_id;
    this.search_by_customer_dto.associated_product_id = associated_product_id;
    this.goals_selected = goals_selected;
    this.current_investor_goal = current_investor_goal;
    await this.loadActiveGoals();
    if (this.has_active_goals) {
      await this.getCustomerInvestorGoalBalance();
    } else {
      await this.getKuspitPosition();
    }
    await this.getAssociatedProduct();
    this.view.$emit('loadingStep', false);
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  get balance_formatted() {
    return currencyFormat(this.balance);
  }

  get balance_fixed() {
    return Math.floor(this.balance);
  }

  get free_balance_formatted() {
    return currencyFormat(this.free_balance);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        this.has_active_goals = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_active_goals'));
    }
  }

  getKuspitPosition = async () => {
    try {
      const kuspit_account = await this.get_has_kuspit_account_query.execute();
      if (kuspit_account.has_account) {
        const kuspit_position_data = await this.get_customer_kuspit_position_query.execute();
        if (kuspit_position_data.has_funds) {
          this.balance = Number(kuspit_position_data.position);
          this.free_balance = this.balance;
        } else {
          this.view.$emit('nextStep');
        }
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_kuspit_position'));
    }
  }

  getAssociatedProduct = async () => {
    try {
      // eslint-disable-next-line max-len
      const associated_products = await this.get_associated_product_query.execute(this.customer_investor_goal_balance.investment_product_id);

      associated_products.forEach((product) => {
        if (this.goals_selected.find(
          (item) => item === product.investor_goal_id,
        )) {
          const active_goal_amount = this.getCurrentGoalAmount(product.investor_goal_id);
          this.associated_product.push({
            investor_goal_id: product.investor_goal_id,
            label: (product.custom_investor_goal) ? product.custom_investor_goal.goal_name
              : product.investment_goal_type.label,
            active_goal_amount,
            active_goal_amount_field: active_goal_amount.toString(),
            recurrent_assigned_amount: product.monthly_required_amount.toString(),
          });
        }
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_associated_product'));
    }
  };

  getCustomerInvestorGoalBalance = async () => {
    try {
      // eslint-disable-next-line max-len
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query.execute(this.customer_investor_goal_balance);
      this.balance = unassigned_balance;
      this.free_balance = this.balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_customer_investor_goal_balance'));
      }
    }
  }

  changeActiveGoalAmount = (index: number) => {
    let active_goal_amount = (this.associated_product[index].active_goal_amount_field)
      ? Number(parseFloat(this.associated_product[index].active_goal_amount_field.replaceAll(/[^\d.-]/g, '')).toFixed(2))
      : 0;
    active_goal_amount = (active_goal_amount < 0) ? 0 : active_goal_amount;
    this.associated_product[index].active_goal_amount = active_goal_amount < this.balance
      ? active_goal_amount : this.balance;
    this.associated_product[index].active_goal_amount_field = active_goal_amount < this.balance
      ? currencyFormat(active_goal_amount) : currencyFormat(this.balance);
    this.validateBalanceAndAmountSlider();
  }

  changeActiveGoalAmountSlider = (index: number) => {
    // eslint-disable-next-line max-len
    this.associated_product[index].active_goal_amount_field = this.associated_product[index].active_goal_amount < this.balance
      ? currencyFormat(this.associated_product[index].active_goal_amount)
      : currencyFormat(this.balance);
    this.validateBalanceAndAmountSlider();
  }

  validateBalanceAndAmountSlider = () => {
    const amount_sliders = this.associated_product.map((obj) => (
      Number(parseFloat(obj.active_goal_amount_field.replaceAll(/[^\d.-]/g, '')))
    ));
    const total_amount_sliders = amount_sliders.reduce(
      (total, currentValue) => total! + currentValue!, 0,
    );

    if (total_amount_sliders! > this.balance) {
      this.is_disabled = true;
      this.free_balance = -1 * (this.balance - total_amount_sliders!);
    } else {
      this.is_disabled = false;
      this.free_balance = this.balance - total_amount_sliders!;
    }
  }

  getCurrentGoalAmount = (investor_goal_id: string) => {
    let active_goal_amount = 0;
    Object.entries(this.current_investor_goal.investor_goals).forEach((item: any) => {
      if (item[1].investor_goal_id === investor_goal_id) {
        active_goal_amount = item[1].active_goal_amount;
        this.free_balance -= active_goal_amount;
      }
    });
    return active_goal_amount;
  }

  sendInvestorGoals = () => {
    this.view.$emit('loadingStep', true);
    let total_active_goals_balance = 0;
    this.associated_product.forEach((product) => {
      this.investor_goals_dto.investor_goals.push({
        investor_goal_id: product.investor_goal_id,
        active_goal_amount: product.active_goal_amount_field.replaceAll(/[^\d.-]/g, ''),
        recurrent_assigned_amount: product.recurrent_assigned_amount.toString(),
      });
      total_active_goals_balance += Number(parseFloat(product.active_goal_amount_field.replaceAll(/[^\d.-]/g, '')));
    });
    this.investor_goals_dto.total_active_goals_balance = total_active_goals_balance.toString();
    this.investor_goals_dto.unassigned_balance = this.free_balance.toString();
    this.view.$emit('loadingStep', false);
    this.view.$emit('changeInvestorGoals', this.investor_goals_dto);
    this.view.$emit('nextStep');
  };
}
