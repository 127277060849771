































































































import { Component, PropSync, Vue } from 'vue-property-decorator';

import StepDistributeBalancePocketViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-pocket/step-distribute-balance-pocket-view-model';

@Component({ name: 'StepDistributeBalancePocket' })
export default class StepDistributeBalancePocket extends Vue {
  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  @PropSync('goalsSelected', { type: Array, required: true })
  goals_selected!: Array<any>;

  @PropSync('investorGoals', { type: Object, required: true })
  investor_goal!: Record<string, any>;

  @PropSync('isLoading', { type: Boolean, required: true, default: false })
  is_loading!: boolean;

  step_distribute_balance_pocket_model = Vue.observable(
    new StepDistributeBalancePocketViewModel(this),
  );

  created() {
    this.step_distribute_balance_pocket_model.initialize(
      this.associated_product_id,
      this.goals_selected,
      this.investor_goal,
    );
  }
}
